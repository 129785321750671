import { buildCollection, buildProperty } from "@firecms/core";
import JSONTextField from "../custom/JSONTextField";

export type ConversationEventType = "started" | "updated" | "ended";

type ConditionalHeaders = {
  name: string,
  headers: Record<string, string>,
  condition: {
    field: string,
    operator: string,
    value: string
  }
}

export type TriggerConfiguration = {
  name: string;
  onEvent: ConversationEventType;
  useAsFallback: boolean;
  onFieldsCompleted: string[];
  endpoint: string;
  headerType: "static" | "conditional";
  headers: Record<string, any>;
  conditionalHeaders: ConditionalHeaders[]
  body: Record<string, any>;
}

export const triggerCollections = buildCollection<TriggerConfiguration>({
  name: "Triggers",
  singularName: "Trigger",
  textSearchEnabled: true,
  path: "triggers",
  id: "trigger",
  icon: "rocket_launch",
  group: "Admin",
  permissions: ({ authController, user }) => ({
    read: true,
    edit: true,
    create: true,
    delete: true
  }),
  properties: {
    name: buildProperty({
      name: "Name",
      validation: { required: true },
      dataType: "string"
    }),
    onEvent: buildProperty({
      name: "On Conversation Event",
      description: "The event that when a conversation starts, it's updated with a message or it has ended.",
      longDescription: `
      There are 3 possible events: 
        - started. When the conversation is created.
        - updated. When the conversation is updated, either there is a new message or new data fields.
        - ended. When the conversation is finalized.
      `,
      validation: { required: true },
      dataType: "string",
      enumValues: {
        started: "Started",
        updated: "Updated",
        ended: "Ended"
      }
    }),
    onFieldsCompleted: ({ values }) => {
      return {
        name: "On Fields Completed",
        description: "This will be triggered if custom properties from the conversation are fulfilled.",
        validation: { required: false },
        dataType: "array",
        of: {
          dataType: "string",
          validation: { trim: true }
        },
        disabled: values.onEvent === "started" && {
          disabledMessage: "Event trigger only available for 'updated' or 'ended' events.",
          clearOnDisabled: true
        }
      }
    },
    useAsFallback: ({ values }) => {
      return {
        name: "Use as Fallback",
        description: "When enabled, this trigger will emmit an event when the conversation has ended if no other trigger has ran already.",
        validation: { required: false },
        dataType: "boolean",
        disabled: values.onEvent !== "ended" && {
          disabledMessage: "Fallback trigger only available for 'ended' events.",
          clearOnDisabled: true
        },
        defaultValue: false
      }
    },
    endpoint: buildProperty({
      name: "Endpoint",
      validation: { required: true },
      dataType: "string"
    }),
    headerType: buildProperty({
      name: "Header Type",
      description: "This field contains the type of headers desired, static or conditional.",
      validation: { required: true },
      dataType: "string",
      defaultValue: "static",
      enumValues: {
        static: "Static",
        conditional: "Conditional"
      }
    }),
    headers: ({ values }) => {
      return {
        name: "Static Header",
        description: `This field contains the headers included in the http request. ${values.headerType !== "static" ? "Disabled because the header type is not static." : ""}`,
        validation: { required: false },
        dataType: "map",
        keyValue: true,
        expanded: values.headerType === "static",
        disabled: values.headerType !== "static"
      }
    },
    conditionalHeaders: ({ values }) => {
      return {
        dataType: "array",
        description: `This field contains the headers included in the http request based on some conditions. ${values.headerType !== "conditional" ? "Disabled because the header type is not conditional." : ""}`,
        name: "Conditional Headers",
        expanded: values.headerType === "conditional",
        disabled: values.headerType !== "conditional",
        of: {
          dataType: "map",
          name: "Condition",
          properties: {
            name: buildProperty({
              name: "Name",
              description: "Name of the conditional header.",
              validation: { required: true },
              dataType: "string"
            }),
            headers: buildProperty({
              name: "Headers",
              description: "Headers to be included in the request when the condition it's satisfied.",
              validation: { required: true },
              dataType: "map",
              keyValue: true
            }),
            condition: buildProperty({
              name: "Condition",
              validation: { required: true },
              dataType: "map",
              properties: {
                field: buildProperty({
                  name: "Custom Variable",
                  description: "The custom variable to be evaluated. It needs to be one from the chatbot Customer Variables.",
                  validation: { required: true, trim: true },
                  dataType: "string"
                }),
                operator: buildProperty({
                  name: "Operator",
                  description: "The operator to be used in the condition",
                  longDescription: " The comparison is not case sensitive.\nIS checks equality, IS NOT checks inequality and CONTAINS checks inclusion",
                  validation: { required: true },
                  dataType: "string",
                  enumValues: {
                    IS: "IS",
                    "IS NOT": "IS NOT",
                    CONTAINS: "CONTAINS"
                  }
                }),
                value: buildProperty({
                  name: "Value",
                  description: "The value to be compared with the custom variable.",
                  validation: { required: true },
                  dataType: "string"
                })
              }
            })
          }
        }
      }
    },
    body: buildProperty({
      name: "Body",
      validation: { required: false },
      dataType: "map",
      keyValue: true,
      customProps: {
        editable: true
      },
      defaultValue: {
        "customField.appointment_request": "data.appointment_date",
        source: "client.referral",
        name: "data.name",
        phone: "data.phone",
        "customField.ai_chat_flow": "data.ai_chat_flow",
        email: "data.email"
      },
      description: "This field is the body payload of the request",
      Field: JSONTextField
    })
  }
})
